<template>
    <div>
        <styled-interface
            no-padding
            no-overflow
            hide-toolbar>
            <div class="layout align-center row wrap">
                <div class="flex xs12 lg6 people bg-white">
                    <div class="d-flex h-screen justify-center align-center">
                        <div class="pb-26">
                            <div class="text-center heading-text">
                                Welcome to
                            </div>
                            <div class="layout align-center justify-center">
                                <img
                                    class="flex xs6"
                                    src="/img/BuyerBridge_LOGO_reverse.png">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex xs12 lg6">
                    <div class="align-center justify-center row fill-height">
                        <div v-if="step === null">
                            <loader class="my-5" />
                        </div>

                        <div v-if="step === 'createUser'">
                            <styled-card
                                :hide-toggle="true"
                                class="flex xs6 mx-auto">
                                <template #heading>
                                    Create a BuyerBridge Login
                                </template>

                                <div class="px-4 mx-4 pt-4">
                                    <p>The final step is to create a BuyerBridge login.</p>
                                </div>

                                <div class="px-4">
                                    <v-form
                                        id="sign-up-form"
                                        ref="form"
                                        v-model="valid"
                                        @submit.prevent="submitSignUpForm">
                                        <v-text-field
                                            v-model="organizationName"
                                            :rules="[...requiredRules]"
                                            label="Organization"
                                            type="text"
                                            class="styled-field ma-4"
                                            @change="resetAgreements" />
                                        <v-text-field
                                            v-model="fullName"
                                            :rules="[...requiredRules]"
                                            label="Full Name"
                                            type="text"
                                            class="styled-field ma-4" />
                                        <v-text-field
                                            v-model="email"
                                            :rules="[...emailRules, ...requiredRules]"
                                            label="Email"
                                            type="email"
                                            class="styled-field ma-4" />
                                        <v-text-field
                                            v-model="password"
                                            :rules="[...passwordRules, ...requiredRules, passwordsMatch]"
                                            label="Password"
                                            type="password"
                                            class="styled-field mx-4 mt-4 mb-0"
                                            @keydown="serverPasswordErrors = []" />
                                        <div
                                            v-for="error in serverPasswordErrors"
                                            :key="error.id"
                                            class="mx-4 mt-0">
                                            <small
                                                class="red--text"
                                                v-html="error" />
                                        </div>

                                        <v-text-field
                                            v-model="confirmPassword"
                                            :rules="[...passwordRules, ...requiredRules, passwordsMatch]"
                                            label="Confirm Password"
                                            type="password"
                                            class="styled-field ma-4 mt-4" />

                                        <v-checkbox
                                            v-model="privacyPolicyIsChecked"
                                            :rules="[...requiredRules]"
                                            class="styled-checkbox-small ma-4">
                                            <template #label>
                                                <a @click="showPrivacyPolicy = true">
                                                    Privacy Policy
                                                </a>
                                            </template>
                                        </v-checkbox>

                                        <v-checkbox
                                            v-model="termsIsChecked"
                                            :rules="[...requiredRules]"
                                            class="styled-checkbox-small ma-4">
                                            <template #label>
                                                <a @click="showTerms = true">
                                                    Terms of Use
                                                </a>
                                            </template>
                                        </v-checkbox>

                                        <div class="ma-4 text-center">
                                            <styled-button
                                                :disabled="!valid"
                                                :loading="signUpRequestLoading"
                                                class="play-button"
                                                type="submit">
                                                Lets Go!
                                            </styled-button>
                                        </div>
                                        <v-alert
                                            class="ma-4"
                                            :value="signUpRequestErrorMessage"
                                            color="error"
                                            icon="warning"
                                            outline>
                                            {{ signUpRequestErrorMessage || 'Error signing up. Please contact support.' }}
                                        </v-alert>
                                    </v-form>

                                    <agency-agreement-modal
                                        v-if="showPrivacyPolicy"
                                        :value="true"
                                        :with-footer-actions="false"
                                        @close="showPrivacyPolicy = false">
                                        <template #intro>
                                            <h2>
                                                Privacy Policy
                                            </h2>
                                            <p>
                                                When white-labeling the BuyerBridge platform, it's important to ensure that your organization has its
                                                own Privacy Policy and Terms of Use in place. By opting in, you'll agree to use our provided Privacy Policy
                                                and Terms of Use templates, customized with your organization’s name. These documents will reflect your
                                                company, not BuyerBridge, ensuring compliance and transparency for your users.
                                            </p>
                                        </template>

                                        <template #content>
                                            <div
                                                class="content">
                                                <div v-html="privacyPolicyDoc" />
                                            </div>
                                        </template>
                                    </agency-agreement-modal>

                                    <agency-agreement-modal
                                        v-if="showTerms"
                                        :value="true"
                                        :with-footer-actions="false"
                                        @close="showTerms = false">
                                        <template #intro>
                                            <h2>
                                                Terms of Use
                                            </h2>
                                            <p>
                                                When white-labeling the BuyerBridge platform, it's important to ensure that your organization has its
                                                own
                                                Privacy Policy and Terms of Use in place. By opting in, you'll agree to use our provided Privacy Policy
                                                and
                                                Terms of Use templates, customized with your organization’s name. These documents will reflect your
                                                company,
                                                not BuyerBridge, ensuring compliance and transparency for your users.
                                            </p>
                                        </template>

                                        <template #content>
                                            <div
                                                class="content">
                                                <div v-html="termsDoc" />
                                            </div>
                                        </template>
                                    </agency-agreement-modal>
                                </div>
                            </styled-card>
                        </div>
                    </div>
                </div>
            </div>
        </styled-interface>
    </div>
</template>

<script>
import axios from 'axios';
import StyledInterface from '@/components/globals/StyledInterface';
import StyledCard from '@/components/globals/StyledCard';
import StyledButton from '@/components/globals/StyledButton';
import { emailRules, requiredRules, passwordRules } from '@/helpers/validationRules';
import Loader from '@/components/globals/Loader.vue';
import AgencyAgreementModal from '@/components/resellers/settings/partials/AgencyAgreementModal.vue';
import organizationsSettings from '@/mixins/organizations-settings';
export default {
    components: {
        AgencyAgreementModal,
        StyledInterface,
        StyledCard,
        StyledButton,
        Loader
    },
    mixins: [ organizationsSettings ],
    data() {
        return {
            axios,
            agencyInvite: null,
            emailRules,
            requiredRules,
            passwordRules,
            response: null,
            resellerUuid: null,
            step: null,
            fbUrl: null,
            fbUrlError: false,
            fullName: '',
            email: '',
            password: '',
            confirmPassword: '',
            valid: false,
            signUpRequestLoading: false,
            signUpRequestError: false,
            signUpRequestErrorMessage: null,
            organizationName: null,
            privacyPolicy: {},
            terms: {},
            showTerms: false,
            showPrivacyPolicy: false,
            privacyPolicyIsChecked: false,
            termsIsChecked: false,
            serverPasswordErrors: []
        };
    },
    computed: {
        passwordsMatch() {
            return this.password == this.confirmPassword ? true : 'The passwords do not match.';
        },
        privacyPolicyDoc() {
            return this.processContent(this.privacyPolicy.body, { name: this.organizationName, url: null });
        },
        termsDoc() {
            return this.processContent(this.terms.body, { name: this.organizationName, url: null });
        }
    },
    created() {
        this.init();
    },
    methods: {
        async init() {
            // Set the axios credentials
            this.axios = axios.create();
            this.axios.defaults.headers.common['BB-Token'] = process.env.VUE_APP_BB_TOKEN;
            this.axios.defaults.baseURL = process.env.VUE_APP_MVP_API_PUBLIC_BASE_URI;

            // Check if the uuid is in the query parameters
            if (this.$route.query.id) {
                // Check if the uuid in the query parameters is not the same as the uuid in local storage
                // If they are different clear the local storage and set the new uuid in local storage
                if (!(this.$route.query.id === localStorage.getItem('resellerUuid'))) {
                    this.clearLocalStorage();
                }
            }

            await this.setUuid();
            await this.setupLegalAgreements();
        },
        setStepCreateUser() {
            this.step = 'createUser';
        },
        async setUuid() {
            if (this.$route.query.id) {
                localStorage.setItem('resellerUuid', this.$route.query.id);
            }

            this.resellerUuid = this.$route.query.id || localStorage.getItem('resellerUuid');
            await this.getAgencyInviteData();
        },
        clearLocalStorage() {
            localStorage.removeItem('resellerUuid');
        },
        submitSignUpForm() {
            this.$refs.form.validate();
            if (!this.valid || !this.privacyPolicyIsChecked || !this.privacyPolicyIsChecked) {
                return;
            }

            this.makeSignUpRequest();
        },
        async getAgencyInviteData() {
            try {
                const response = await this.axios.get(`/agency-invite/${this.resellerUuid}`);
                this.response = response.data;
                this.agencyInvite = response.data;

                // Set the organization name
                this.organizationName = this.response.name;

                this.setStepCreateUser();
            } catch (error) {
                console.log(error);
            }
        },
        async makeSignUpRequest() {
            try {
                this.signUpRequestLoading = true;
                this.signUpRequestError = false;
                this.signUpRequestErrorMessage = null;

                await this.axios.post('/agency-onboarding/sign-up', {
                    'uuid': this.resellerUuid,
                    'organization_name': this.organizationName,
                    'name': this.fullName,
                    'email': this.email,
                    'password': this.password,
                    'password_confirmation': this.confirmPassword,
                    'privacy_policy': this.privacyPolicyIsChecked,
                    'terms': this.termsIsChecked,
                    'privacy_policy_doc': this.privacyPolicyDoc,
                    'terms_doc': this.termsDoc,
                });

            } catch (error) {
                this.serverPasswordErrors = error.response.data.errors.password;
                error.response.data.message.includes('The email has already been taken.') ? this.signUpRequestErrorMessage = 'The email has already been taken. Try a different email or contact support.' : null;

                return;
            } finally {
                this.signUpRequestLoading = false;
            }

            // Clear out anything used in local storage
            // for the sign up process
            this.clearLocalStorage();

            // Run the logout logic to make sure the UI behaves
            // correctly if someone that is already logged in signs up an agency
            await this.$store.dispatch('logout');

            // Make the login request and set the auth token
            await this.$store.dispatch('login', {
                email: this.email,
                password: this.password,
                init: true
            });
        },
        async getLegalAgreements(docObj, docName) {
            try {
                const { data } = await this.$apiRepository.getLegalAgreementByNameServerSide(docName);
                this[docObj] = data;
            } catch (e) {
                this[docObj] = {};
            }
        },
        async setupLegalAgreements() {
            await this.getLegalAgreements('privacyPolicy', 'agency_legal_agreement_privacy_policy');
            await this.getLegalAgreements('terms', 'agency_legal_agreement_terms');
        },
        resetAgreements() {
            this.privacyPolicyIsChecked = false;
            this.termsIsChecked = false;
        }
    }
};
</script>

<style lang="scss" scoped>
.width-75 {
  width: 75%;
}

.fb-button {
  border-radius: 7px;
  text-align: center;
  background-color: #1A77F2;
  padding: 4px;
  color: white;
  font-weight: 700;
  font-size: 17px;
  text-decoration: none;
}

.bg-white {
  background-color: white;
}

.heading-text {
  font-size: 36px;
  color: #4FA2E9;
}

.pb-26 {
  padding-bottom: 260px;
}

@media (min-width: $bp-lg) {
  .h-screen {
    height: 100vh;
  }

  .people {
    background-image: url('/img/onboarding-people.svg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: left bottom;
    background-size: 700px;
    width: 50px;
  }
}
</style>

<style>
.interface-type-minimal .primary-container {
  padding: 0px !important;
}
</style>
